// Get visible publications

export default (publications, { sortBy }) => {
  return publications
  //   .filter((publication) => {
  //     const createdAtMoment = moment(publication.createdAt);
  //     const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
  //     const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;

  //   })
    .sort((a, b) => {
      if (sortBy === 'date') {
        return a.createdAt < b.createdAt ? 1 : -1;
      }
    });
};
