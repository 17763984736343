/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useContext, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import DescriptionIcon from '@material-ui/icons/Description';
import Button from '@material-ui/core/Button';

import MenuContext from '../context/menu-context';

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  avatarContainer: {
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: theme.spacing(4),
    },
  },
  avatar: {
    margin: 10,
    backgroundColor: theme.palette.grey['200'],
    color: theme.palette.text.primary,
  },
  spaceBetween: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: 0,
    },
  },
  publicationDetails: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  inlineRight: {
    width: '30%',
    textAlign: 'right',
    marginLeft: 10,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: 0,
      textAlign: 'center',
    },
  },
  secondaryButton: {
    background: theme.palette.secondary['100'],
    color: 'white',
  },
  primaryButton: {
    marginRight: theme.spacing(2),
  },
});

const CardPublication = (props) => {
  const { classes, publication } = props;
  const { firebaseHook } = useContext(MenuContext);

  const [isCardRaised, setRaised] = useState(false);

  const toggleRaised = () => setRaised(true);
  const toggleUnRaise = () => setRaised(false);

  const downloadPdf = (fileName) => {
    // const folderPath = 'publications/';
    // const fileName = 'healthcom2014.pdf';
    const pdf = firebaseHook
      .storage()
      .ref()
      .child(`publications/${fileName}`);

    // var starsRef = storageRef.child('images/stars.jpg');
    // Get the download URL
    pdf
      .getDownloadURL()
      .then((url) => {
        // Insert url into an <img> tag to "download"
        window.open(url);
        // console.log(url);
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        // eslint-disable-next-line default-case
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist
            break;

          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;

          case 'storage/canceled':
            // User canceled the upload
            break;
          case 'storage/unknown':
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div {...props}>
      <Card
        className={classes.container}
        onMouseOver={toggleRaised}
        onMouseOut={toggleUnRaise}
        raised={isCardRaised}
      >
        <div className={classes.spaceBetween}>
          <div className={classes.avatarContainer}>
            <Avatar className={classes.avatar} alt="">
              <DescriptionIcon />
            </Avatar>
          </div>
          <div className={classes.publicationDetails}>
            <Typography variant="h6" color="primary" gutterBottom>
              <b>{publication.title}</b>
            </Typography>
            {publication.translatedTitle !== '' ? (
              <Typography variant="h6" color="secondary" gutterBottom>
                <b>Translated title: </b>
                <i>{publication.translatedTitle}</i>
              </Typography>
            ) : null}
            <Typography gutterBottom>{publication.authors}</Typography>
            <br />
            <Typography variant="h6" gutterBottom>
              <b>{publication.shortVenue}</b>
            </Typography>
          </div>
        </div>
        <div className={classes.inlineRight}>
          {publication.doi !== '' ? (
            <Button
              target="_blank"
              href={publication.doi}
              color="secondary"
              variant="outlined"
              className={classes.primaryButton}
            >
              DOI
            </Button>
          ) : null}
          <Button
            variant="contained"
            color="primary"
            className={classes.secondaryButton}
            onClick={() => downloadPdf(publication.fileName)}
          >
            PDF
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default withStyles(styles)(CardPublication);
