import React, { useEffect, useContext } from "react"
import { connect } from "react-redux"
// Material ui imports
import withStyles from "@material-ui/core/styles/withStyles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Grid from "@material-ui/core/Grid"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import Grow from "@material-ui/core/Grow"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"

// Custom
import MenuContext from "../context/menu-context"
import SelectPublications from "../selectors/publications"
import CardPublication from "../components/CardPublication"
import { fethPublicationsFirestore } from "../actions/publications"

const styles = theme => ({
  // root: {
  //   flexGrow: 1,
  //   backgroundColor: theme.palette.grey['300'],
  //   overflow: 'hidden',
  //   // padding: 20,
  //   'min-height': '100vh', /* will cover the 100% of viewport */
  //   display: 'block',
  //   position: 'relative',
  //   // 'padding-bottom': '100px',
  // },
  grid: {
    width: "90%",
    marginTop: 10,
  },
  ul: {
    "list-style-type": "none",
    margin: 0,
    padding: 0,
  },
  progress: {
    margin: theme.spacing(2),
  },
  container: {
    display: "flex",
    "flex-direction": "column",
    "justify-content": "center",
    "align-items": "center",
    "text-align": "center",
    "min-height": "50vh",
  },
  title: {
    padding: theme.spacing(2),
  },
})

const Publications = props => {
  // getFirestorePublications is from redux action connected so that it can dispatch
  const { classes, getFirestorePublications } = props
  const { setMenu, firebaseHook } = useContext(MenuContext)
  const [checked, setChecked] = React.useState(true) // for animation/transformation

  const currentPath = props.location.pathname

  // Run once after initial render
  useEffect(() => {
    setMenu(currentPath)
  }, [currentPath, setMenu])

  // Monitor firebaseHook so that when it is created in context,
  // then call the action to fetch publications
  useEffect(() => {
    if (firebaseHook) {
      getFirestorePublications(firebaseHook.db)
    }
  }, [firebaseHook, getFirestorePublications])

  return (
    <React.Fragment>
      <CssBaseline />
      {props.publications.length === 0 ? (
        <div className={classes.container}>
          <CircularProgress size={60} className={classes.progress} />
        </div>
      ) : (
        <div>
          <Grid container justify="center">
            <Grid
              spacing={4}
              alignItems="center"
              justify="center"
              container
              className={classes.grid}
            >
              {/* <Grid item xs={12}>
                  <div className={classes.title}>
                    <Typography variant="h6" gutterBottom>Publications</Typography>
                  </div>
                </Grid> */}
              <Grid item xs={12}>
                {props.publications.map((publication, index) => (
                  <Grow
                    key={publication.id}
                    in={checked}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(checked ? { timeout: 1000 * (index + 0.48) } : {})}
                  >
                    <CardPublication
                      key={publication.id}
                      publication={publication}
                    />
                  </Grow>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  publications: SelectPublications(state.publications, { sortBy: "date" }),
})

const mapDispatchToProps = dispatch => ({
  getFirestorePublications: database =>
    dispatch(fethPublicationsFirestore(database)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Publications))
